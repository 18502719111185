<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import DetailTugas from "./detail-tugas.vue";
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
    page: {
        title: "Detail Tempat Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        DetailTugas,
    },
    data() {
        return {
            title: "Detail Tempat Uji Kompetensi",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "Detail Tempat Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,

            meta_dokumen: [
                {
                    'nama_dokumen': 'FR.VA Memberikan Kontribusi Dalam Validasi Asesmen'
                },
                {
                    'nama_dokumen': 'FR.IA.11 Ceklis Meninjau Instrumen Asesmen'
                },
                {
                    'nama_dokumen': 'Ceklis Konsultasi Pra Asesmen'
                },
                {
                    'nama_dokumen': 'Ceklis Verifikasi TUK'
                },
                {
                    'nama_dokumen': 'FR.APL-01 Permohonan Sertifikasi Kompetensi + Bukti Persyaratan Dasar'
                },
                {
                    'nama_dokumen': 'FR.APL.02 Asesmen Mandiri + Portofolio Peserta'
                },
                {
                    'nama_dokumen': 'FR.MAPA.01 Merencanakan Aktivitas dan Proses Asesmen'
                },
                {
                    'nama_dokumen': 'FR.MAPA.02 Peta Instrumen Asesmen Hasil Pendekatan Asesmen dan Perencanaan Kerja'
                },
                {
                    'nama_dokumen': 'SKEMA Sertifikasi + Standar Kompetensi Kerja'
                },
                {
                    'nama_dokumen': 'FR.AK.04 Banding Asesmen'
                },
                {
                    'nama_dokumen': 'FR.AK.01 Persetujuan Asesmen dan Kerahasiaan'
                },
                {
                    'nama_dokumen': 'FR.IA.01 Ceklis Observasi Aktivitas di Tempat Kerja Atau Tempat Kerja Simulasi'
                },
                {
                    'nama_dokumen': 'FR.IA.02 Tugas Praktek Demonstrasi'
                },
                {
                    'nama_dokumen': 'FR.IA.03 Pertanyaan Untuk Mendukung Observasi'
                },
                {
                    'nama_dokumen': 'FR.IA.04 Penjelasan Singkat Proyek Terkait Pekerjaan/Kegiatan Terstruktur Lainnya'
                },
                {
                    'nama_dokumen': 'FR.IA.05 Pertanyaan Tertulis Pilihan Ganda + Kunci Jawaban dan Lembar Jawaban'
                },
                {
                    'nama_dokumen': 'FR.IA.06 Pertanyaan Tertulis esai + Kunci Jawaban dan Lembar Jawaban'
                },
                {
                    'nama_dokumen': 'FR.IA.07 Pertanyaan Lisan'
                },
                {
                    'nama_dokumen': 'FR.IA.08 Ceklis Verifikasi Portofolio'
                },
                {
                    'nama_dokumen': 'FR.IA.09 Pertanyaan Wawancara'
                },
                {
                    'nama_dokumen': 'FR.IA.10 Klarifikasi Bukti Pihak Ketiga'
                },
                {
                    'nama_dokumen': 'FR.AK.02 Formulir Rekaman Asesmen Kompetensi'
                },
                {
                    'nama_dokumen': 'FR.AK.03 Umpan Balik dan Catatan Asesmen'
                },
                {
                    'nama_dokumen': 'FR.AK.05 Laporan Asesmen'
                },
                {
                    'nama_dokumen': 'Daftar Hadir'
                },
                {
                    'nama_dokumen': 'FR.AK.06 Meninjau Proses Asesmen'
                },
                {
                    'nama_dokumen': 'FR.VA Pasca Asesmen'
                }
            ]
        };
    },
    mounted() {
    },
    methods: {
        addDokumen() {
            this.meta_dokumen.push({
                nama_dokumen: "",
            });
        },
        removeDokumen(index) {
            this.meta_dokumen.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
    },
};
</script>
<style>
.col-md-custom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.list-inline{
    margin-top: 10px;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}

.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}
.scroll-timeline{
    overflow: auto;
    white-space: nowrap;
}
</style>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab>
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="far fa-user"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Verifikasi Tugas</span>
                                </template>
                                <detail-tugas></detail-tugas>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
